import { createRoot } from 'react-dom/client';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import msalPublicClientApplication from 'core/constants/msal.constants';
import App from 'screens/App';
import monitoringInstance from 'shared/utils/monitoring-provider.utils';
import { decodeJWTToken } from 'shared/utils/token.utils';
import * as serviceWorker from './serviceWorker';

import './index.scss';

msalPublicClientApplication.initialize().then(() => {
  const accounts = msalPublicClientApplication.getAllAccounts();
  if (accounts.length > 0) {
    msalPublicClientApplication.setActiveAccount(accounts[0]);
  }

  msalPublicClientApplication.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;

      msalPublicClientApplication.setActiveAccount(account);
    }
  });

  msalPublicClientApplication.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      if (event.payload !== null) {
        if ('accessToken' in event.payload) {
          const decodedToken = decodeJWTToken(event.payload.accessToken);
          monitoringInstance.setUserId(decodedToken?.sub);
        }
      }
    }
  });

  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(<App />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
